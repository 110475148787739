@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

:root {
  /* Primary Colors */
  --primary-color: #4CCD99; /* Mint Green */
  --primary-dark: #3A9B73; 
  --primary-light: #82EDBC;
  
  /* Secondary Colors */
  --secondary-color: #ea5507; /* Orange */
  --secondary-dark: #c43e00;
  --secondary-light: #ff7a38;
  
  /* Neutral Colors */
  --neutral-dark: #333333;
  --neutral-medium: #666666;
  --neutral-light: #f7f7f7;
}

/* Base Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: var(--neutral-dark);
  background-color: #ffffff;
  line-height: 1.6;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
}

p {
  margin-bottom: 1rem;
}

/* Animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateX(-5%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes zoomIn {
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.7s ease-in-out;
}

.slide-in {
  animation: slideIn 0.7s ease-in-out;
}

.slide-up {
  animation: slideUp 0.7s ease-in-out;
}

.zoom-in {
  animation: zoomIn 0.5s ease-in-out;
}

/* Button Styles */
.button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  position: relative;
  overflow: hidden;
}

.button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 0.8s;
}

.button:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.button-primary {
  background-color: var(--primary-color);
  color: white;
}

.button-primary:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.button-secondary:hover {
  background-color: var(--secondary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Card Styles */
.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  transition: all 0.3s ease;
  border-left: 4px solid transparent;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12);
}

/* Section Styles */
.section {
  padding: 4rem 2rem;
  position: relative;
}

.section-colored {
  background-color: var(--neutral-light);
  position: relative;
}

/* Container */
.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Custom Classes */
.gradient-text {
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
}

/* Layout utilities */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* Responsive utilities */
@media (max-width: 768px) {
  .section {
    padding: 3rem 1rem;
  }
  
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  .container {
    width: 95%;
  }
}

/* Keep original compatibility styles */
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}

.list-item {
  display: list-item;
}

.teleport-show {
  display: flex !important;
  transform: none !important;
}

.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.leaflet-container {
  width: 100%;
  height: 100%;  
}
